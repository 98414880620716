import { icons } from 'src/assets';
import { CHAIN, CHAIN_CONFIG } from 'src/config/ChainConfig';

const formatAddress = (
  address: string,
  startIndex?: number,
  endIndex?: number
) =>
  address
    ? `${address.slice(0, startIndex ?? 6)}...${address.slice(endIndex ?? -4)}`
    : '';

const formatString = (string: string, start: number, end: number) =>
  string ? `${string.slice(0, start)}...${string.slice(-end)}` : '';

const formatBalance = (balance: string) => balance.slice(0, 5);

function capitalizeWords(str: string) {
  if (!str) return;
  const arr = str.split(' ');
  const capitalizedArr = arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
  });
  return capitalizedArr.join(' ');
}

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const isValidEmail = (email: string) => {
  const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return emailReg.test(email);
};
const getEmailDomain = (email: string) => {
  const domainRegex = /@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const matches = email.match(domainRegex);
  return matches ? matches[1] : '';
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const partition = (array: any[], filter: Function) => {
  let pass = [],
    fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
};

const delay = (ms: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('delayed');
    }, ms);
  });
};

const createImageFromUrl = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', (error) => {
      console.log('image.addEventListener  error:', error);

      reject(error);
    });
  });

const dataURLtoFile = (dataUrl: string, filename: string) => {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const blobUrlToFile = (blobUrl: string, filename: string): Promise<File> =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], filename, { type: blob.type });
        resolve(file);
      });
    });
  });

const convertImageToBase64 = (imageFile: File | Blob) => {
  return new Promise((resolve) => {
    const imgUrl = URL.createObjectURL(imageFile);
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imgUrl;
    return (image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    });
  });
};

const parseToken = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64 || ''));
  } catch (err) {
    console.error(err);
    return null;
  }
};

const isLocalhost: boolean = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const getTxBlockExplorerUrl = (
  isOnPolygon: boolean,
  txHash: string
): string => {
  let chain = CHAIN.AMOY;
  let suffix = 'tx';

  if (process.env.REACT_APP_ENV === 'production') {
    chain = CHAIN.POLYGON;
  }

  if (!isOnPolygon) {
    suffix = 'blockchain/c/tx';
    chain = CHAIN.FUJI;

    if (process.env.REACT_APP_ENV === 'production') {
      chain = CHAIN.AVAX;
    }
  }
  const chainConfig = CHAIN_CONFIG[chain];
  const blockExplorerUrl = `${chainConfig.blockExplorerUrl}/${suffix}/${txHash}`;
  return blockExplorerUrl;
};

const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
};

const chunks = (a: any[], size: number) =>
  Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
    a.slice(i * size, i * size + size)
  );

const gatherSocialAccounts = (
  linkedinUrl?: string,
  twitterUrl?: string,
  githubUrl?: string
) => {
  return [
    linkedinUrl && {
      label: 'LinkedIn',
      link: linkedinUrl,
      icon: icons.inOutlineSec
    },
    twitterUrl && {
      label: 'Twitter',
      link: twitterUrl,
      icon: icons.twOutlineSec
    },
    githubUrl && {
      label: 'Github',
      link: githubUrl,
      icon: icons.githubOutlineSec
    }
  ].filter((x) => x);
};
const totalCountByKey = (array: any, keyToCount: string) => {
  let totalCount = 0;

  for (const obj of array) {
    const keyValue = obj[keyToCount];

    if (!isNaN(keyValue)) {
      totalCount += keyValue;
    }
  }

  return totalCount;
};
// const replaceTrChars = (str: string) => {
//   if (!str) return '';
//   return str
//     .replace(/[i̇]/g, 'i')
//     .replace(/[İı]/g, 'i')
//     .replace(/[Şş]/g, 's')
//     .replace(/[Ğğ]/g, 'g')
//     .replace(/[Üü]/g, 'u')
//     .replace(/[Öö]/g, 'o')
//     .replace(/[Çç]/g, 'c');
// };
const replaceTrChars = (str: string) => {
  if (!str) return '';
  return str
    .replace(/[i̇]/g, 'i')
    .replace(/[İı]/g, 'i')
    .replace(/[Şş]/g, 's')
    .replace(/[Ğğ]/g, 'g');
};

const isMobile = /Mobi/.test(navigator.userAgent);

interface IdownloadPdfProps {
  noDownload?: boolean;
  elementName?: string;
  fileName?: string;
}

export {
  blobUrlToFile,
  capitalizeWords,
  chunks,
  convertImageToBase64,
  createImageFromUrl,
  dataURLtoFile,
  delay,
  formatAddress,
  formatBalance,
  formatString,
  gatherSocialAccounts,
  getEmailDomain,
  getTxBlockExplorerUrl,
  groupBy,
  guidGenerator,
  isMobile,
  isValidEmail,
  isValidUrl,
  parseToken,
  partition,
  replaceTrChars,
  totalCountByKey
};
