import axios from 'axios';
import ENV from '../config/environment';

const baseUrl = "https://bj7nlbp3i0.execute-api.eu-west-3.amazonaws.com/v1/";

const getToken = () => {
  const idToken = localStorage.getItem('diplomadeIdToken');
  const refreshToken = localStorage.getItem('diplomadeRefreshToken');
  const authToken = `${idToken},${refreshToken}`;
  return idToken ? authToken.replaceAll('"', '') : '';
};

export const ocuApi = axios.create({
  baseURL: baseUrl
});

ocuApi.interceptors.request.use(async (config) => {
  const idToken = getToken();
  // console.log("idToken:", idToken)
  config.headers['Authorization'] =
    config.headers['Authorization'] || idToken || '';

  // console.log("request config:", config)
  return config;
});

ocuApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    throw error?.response?.data || error;
  }
);
