export enum QueryKeys {
  AwaitingSignaturesBadges = 'awaitingSignaturesBadges',
  BadgeIssuedUsers = 'badgeIssuedUsers',
  Badges = 'badges',
  EarnedBadges = 'earnedBadges',
  ImportedBadges = 'importedBadges',
  OcuBadges = 'OcuBadges',
  Issuer = 'issuer',
  IssuerEarnedBadges = 'issuerEarnedBadges',
  IssuerIssuedBadges = 'issuerIssuedBadges',
  IssuerBadgeDetail = 'issuerBadgeDetail',
  IssuerPublicBadgeDetail = 'issuerPublicBadgeDetail',
  LivenessSession = 'livenessSession',
  SubsPackages = 'subsPackages',
  User = 'user',
  UserBadgeDetail = 'userBadgeDetail',
  UserImportedBadgeDetail = 'userImportedBadgeDetail',
  UserIssuedBadges = 'userIssuedBadges',
  UserPublicBadgeDetail = 'userPublicBadgeDetail',
  UserPublicImportedBadgeDetail = 'userPublicImportedBadgeDetail',

}
