// import { Web3AuthCore } from '@web3auth/core';
import { Web3Auth } from '@web3auth/single-factor-auth';

import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react';
import { CHAIN_DATA } from '../config/ChainConfig';

import { useQueryClient } from '@tanstack/react-query';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { providers, Signer } from 'ethers';
import { toast } from 'react-toastify';
import ENV from '../config/environment';
import useLocalStorage from '../hooks/useStorage';
import { User } from '../models/user';
import {
  getUser,
  refreshIdToken,
  updatePublicAddress
} from '../services/profile';
import { parseToken } from '../utils/helperUtils';
import { tKey } from './tkey/tkey';

export interface IWeb3AuthContext {
  web3Auth: Web3Auth | null;
  provider: providers.Provider | null;
  isLoading: boolean;
  isInitialized: boolean;
  user: User;
  signer: Signer;
  setUser: (user: User) => void;
  loginWithCustomAuth: (idToken: string) => Promise<void>;
  registerWithCustomAuth: (
    idToken: string,
    isGoogleLogin?: boolean
  ) => Promise<void>;
  logoutFromCustomAuth: () => Promise<void>;

  setIsLoading: (loading: boolean) => void;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  isLoading: true,
  isInitialized: false,
  signer: null,
  user: null,
  setUser: (user: User) => {},
  setIsLoading: (loading: boolean) => {},
  loginWithCustomAuth: async (idToken: string) => {},
  registerWithCustomAuth: async (
    idToken: string,
    isGoogleLogin?: boolean
  ) => {},
  logoutFromCustomAuth: async () => {}
});

export function useWeb3Auth(): IWeb3AuthContext {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthState {
  children?: React.ReactNode;
}
interface IWeb3AuthProps {
  children?: React.ReactNode | any;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({
  children
}: IWeb3AuthProps) => {
  const queryClient = useQueryClient();

  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null);
  const [privateKey, setPrivateKey] = useState<any>();
  const [provider, setProvider] = useState<providers.Provider | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [signer, setSigner] = useState<Signer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const [idToken, setIdToken] = useLocalStorage('diplomadeIdToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage(
    'diplomadeRefreshToken',
    ''
  );
  // console.log('refreshToken:', { idToken, refreshToken });

  useEffect(() => {
    const checkLogin = async () => {
      if (!idToken || !refreshToken) return setIsLoading(false);
      try {
        const refresshedToken = await refreshIdToken();
        if (refresshedToken?.idToken) {
          setIdToken(refresshedToken?.idToken);
          triggerLoginWithCustomAuth(refresshedToken?.idToken);
        } else {
          console.log('checkLogin  error occured while refreshing token:');

          logoutFromCustomAuth();

          toast.error('Error Occured!');
        }
      } catch (error) {
        console.log('error', error);
        logoutFromCustomAuth();
        // toast.error(error?.message);
      }
    };

    const init = async () => {
      // Initialization of Service Provider
      try {
        await (tKey.serviceProvider as any).init();
        setIsInitialized(true);
        checkLogin();
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  // !! custom auth

  const registerWithCustomAuth = async (
    idToken: string,
    isGoogleLogin?: boolean
  ) => {
    await triggerRegisterWithCustomAuth(idToken, isGoogleLogin);
    await tKey.initialize();
  };
  const loginWithCustomAuth = async (idToken: string) => {
    await triggerLoginWithCustomAuth(idToken);
    await tKey.initialize();
  };

  const triggerLoginWithCustomAuth = async (idToken: string) => {
    if (!tKey) {
      console.log('no tKey yet');
      return;
    }
    try {
      // console.log('web3auth clientId:', clientId);
      const parsedToken = parseToken(idToken);
      // console.log('triggerLoginWithCustomAuth  parsedToken:', parsedToken);
      const emailHMACForIntercom = parsedToken.emailHMACForIntercom;
      const userDetails = await getUser();
      // console.log('custom login  userDetails:', userDetails);

      if (!userDetails?.email) {
        logoutFromCustomAuth();
        return;
      }
      let _user: any;

      _user = {
        email: userDetails?.email,
        emailVerified: userDetails?.email_verified,
        walletAddress: userDetails?.publicAddress,
        totpRequired: userDetails?.otpEnabled,
        emailHMACForIntercom
      };
      _user.userDetails = userDetails || null;
      _user.isIssuer = userDetails.upgradeDate ? true : false;
      _user.userDetails.remainingCredits =
        +_user?.userDetails?.remainingCredits || 0;
      _user.userDetails.totalCredits = +_user?.userDetails?.totalCredits || 0;

      // console.log('triggerLoginWithCustomAuth  _user:', _user);

      setUser(_user);
      setIsLoading(false);
      //set user first then set wallet to avoid waiting
      try {
        const clientId = ENV.WEB3AUTH_CLIENT_ID;

        const ownSubVerifierDetails = {
          typeOfLogin: 'jwt',
          verifier: ENV.WEB3AUTH_SUB_VERIFIER_SELF,
          clientId: clientId,
          jwtParams: {
            id_token: idToken,
            verifierIdField: 'email'
          }
        };
        // console.log('ownSubVerifierDetails:', ownSubVerifierDetails);
        const loginResponse = await (
          tKey.serviceProvider as any
        ).triggerAggregateLogin({
          aggregateVerifierType: 'single_id_verifier',
          verifierIdentifier: ENV.WEB3AUTH_VERIFIER,
          subVerifierDetailsArray: [ownSubVerifierDetails]
        });

        const { privKey: privateKey } = loginResponse.finalKeyData;

        const currentChainConfig = CHAIN_DATA;

        const ethereumPrivateKeyProvider = new EthereumPrivateKeyProvider({
          config: {
            chainConfig: currentChainConfig
          }
        });
        await ethereumPrivateKeyProvider.setupProvider(privateKey);
        const _provider = new providers.Web3Provider(
          ethereumPrivateKeyProvider.provider
        );
        const signer = _provider.getSigner();
        setSigner(signer);
      } catch (error) {
        console.log('error', error);
        // toast.error('Error occured while tkey trigger');
      }
    } catch (error) {
      console.log('error', error);
      // setUser(null);
      setIsLoading(false);

      return toast.error('Error occured while getting user');
    }
  };

  const triggerRegisterWithCustomAuth = async (
    idToken: string,
    isGoogleLogin: boolean
  ) => {
    if (!tKey) {
      console.log('no tKey yet ');
      return;
    }
    try {
      const clientId = ENV.WEB3AUTH_CLIENT_ID;
      // console.log('web3auth clientId:', clientId);
      const parsedToken = parseToken(idToken);
      // console.log('parsedToken:', parsedToken);

      const emailHMACForIntercom = parsedToken?.emailHMACForIntercom;

      const customSubVerifierDetails = {
        typeOfLogin: 'jwt',
        verifier: ENV.WEB3AUTH_SUB_VERIFIER_SELF,
        clientId: clientId,
        jwtParams: {
          id_token: idToken,
          verifierIdField: 'email'
        }
      };
      const registerResponse = await (
        tKey.serviceProvider as any
      ).triggerAggregateLogin({
        aggregateVerifierType: 'single_id_verifier',
        verifierIdentifier: ENV.WEB3AUTH_VERIFIER,
        subVerifierDetailsArray: [customSubVerifierDetails]
      });
      // console.log('register response', registerResponse);

      const { privKey: privateKey, evmAddress: publicAddress } =
        registerResponse.finalKeyData;
      console.log('publicAddress:', publicAddress);

      // 1. save user public address to db (update user public address)

      const updatePAResponse = await updatePublicAddress(publicAddress);

      if (updatePAResponse?.idToken) {
        setIdToken(updatePAResponse?.idToken);
        setRefreshToken(updatePAResponse?.refreshToken);

        //@ get user detail to see if he has awaiting issue
        const userDetails = await getUser();

        let _user: any;

        _user = {
          email: userDetails?.email,
          emailVerified: userDetails?.email_verified,
          walletAddress: userDetails?.publicAddress,
          totpRequired: userDetails?.otpEnabled,
          emailHMACForIntercom
        };
        _user.userDetails = userDetails || null;
        _user.isIssuer = userDetails.upgradeDate ? true : false;
        _user.userDetails.remainingCredits =
          +_user?.userDetails?.remainingCredits || 0;
        _user.userDetails.totalCredits = +_user?.userDetails?.totalCredits || 0;

        setUser(_user);
        setIsLoading(false);

        const currentChainConfig = CHAIN_DATA;
        const ethereumPrivateKeyProvider = new EthereumPrivateKeyProvider({
          config: {
            chainConfig: currentChainConfig
          }
        });

        await ethereumPrivateKeyProvider.setupProvider(privateKey);
        const _provider = new providers.Web3Provider(
          ethereumPrivateKeyProvider.provider
        );
        const signer = _provider.getSigner();
        setSigner(signer);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const logoutFromCustomAuth = async () => {
    // remove tokens from localstorage
    setIdToken('');
    setRefreshToken('');
    setIsLoading(false);
    setUser(null);
    setSigner(null);
    queryClient.invalidateQueries();
  };

  const contextProvider = {
    web3Auth,
    provider,
    user,
    signer,
    isLoading,
    isInitialized,
    setUser,
    setIsLoading,
    loginWithCustomAuth,
    registerWithCustomAuth,
    logoutFromCustomAuth
  };
  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};
