import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { videos } from 'src/assets';
import { CenteredStack } from 'src/components/Stack/CenteredStack';

type Props = {};

function WelcomeToOcu({}: Props) {
  return (
    <>
      <Helmet>
        <title>Welcome to Ocu</title>
      </Helmet>
      <CenteredStack bgcolor={'#E7EAF6'} height="100%" width="100%">
        <CenteredStack
          maxWidth={500}
          pt="2rem"
          gap="4rem"
          height="100%"
          width="100%"
        >
          <Typography
            fontSize={48}
            fontWeight={700}
            textAlign="center"
            variant="h1"
          >
            Great! You have done it.
          </Typography>
          <Stack>
            <video src={videos.openOcu} muted autoPlay loop controls={false} />
          </Stack>

          <Typography
            fontSize={24}
            fontWeight={400}
            textAlign="center"
            variant="h1"
            color="#7C7781"
          >
            You can continue by opening OCU extension from top right.
          </Typography>
        </CenteredStack>
      </CenteredStack>
    </>
  );
}

export default WelcomeToOcu;
