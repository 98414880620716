import ThresholdKey from '@tkey/default';
import WebStorageModule from '@tkey/web-storage';
import SecurityQuestionsModule from '@tkey/security-questions';
import ENV from 'src/config/environment';

// Configuration of Service Provider
const customAuthArgs = {
  baseUrl: `${window.location.origin}/serviceworker`,
  network: 'cyan',
  web3AuthClientId: ENV.WEB3AUTH_CLIENT_ID
};
// Configuration of Modules
const webStorageModule = new WebStorageModule();

// Instantiation of tKey
export const tKey = new ThresholdKey({
  modules: {
    webStorage: webStorageModule
  },
  customAuthArgs: customAuthArgs as any
});
